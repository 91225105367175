import React from "react";
import { EuiBadge } from "@elastic/eui";

export default function TagBadges({ tags }) {
  let badgeTags = tags.map((item, i) => {
    return (
      <EuiBadge
        key={i}
        color="warning"
        iconType="tag"
        style={{ fontSize: "10px", padding: "0 4px" }}
      >
        {item}
      </EuiBadge>
    );
  });
  return <>{badgeTags}</>;
}
