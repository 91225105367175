import React, { Fragment, useState } from "react";

import {
  EuiFormRow,
  EuiButtonIcon,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldNumber,
} from "@elastic/eui";

import NutrientsComboBox from "./NutrientsComboBox";

function ListField({ item, i, list, setList, addInput }) {
  const [nutrient, setNutrient] = useState(item?.nutrient? item.nutrient: null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addInput();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      window.setTimeout(function () {
        form.elements[index + 2].focus();
      }, 10);
      e.preventDefault();
    }
  };

  const handleRemoveItem = (e) => {
    let newList = [...list];
    newList.splice(i, 1);
    setList(newList);
  };

  return (
    <div>
      <EuiFlexGroup style={{ maxWidth: 600 }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label="Nutrient" helpText="">
            <NutrientsComboBox setItem={setNutrient} item={nutrient} />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiFormRow label="Amount" helpText="">
            <EuiFieldNumber
              name="amount"
              append={
                <EuiButtonIcon
                  iconType="cross"
                  aria-label="Remove this"
                  onClick={handleRemoveItem}
                />
              }
              value={item.amount}
              onChange={(e) => {
                let newList = [...list];
                newList[i] = {nutrient: nutrient, amount: e.target.value};
                setList(newList);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
    </div>
  );
}

export default function NutrientInputList({ list, setList }) {
  const addInput = () => {
    setList([...list, {}]);
  };

  const lines = list.map((item, i) => {
    return (
      <ListField
        item={item}
        i={i}
        list={list}
        setList={setList}
        addInput={addInput}
        key={`il-${i}`}
      />
    );
  });

  return (
    <Fragment>
      {lines}
      <EuiSpacer />
      <EuiButtonIcon
        color="success"
        onClick={() => addInput()}
        iconType="plusInCircleFilled"
        size="s"
        display="fill"
      />
    </Fragment>
  );
}
