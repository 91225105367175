import React from "react";

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiForm,
} from "@elastic/eui";

import { isLoggedInVar } from "../cache";
import { useMutation } from "@apollo/client";
import { GoogleLogin } from "react-google-login";

import GOOGLE_LOGIN from "../mutations/googleLogin";

export default function Login() {
  const [googleLogin, { loading: mutationLoading, error: mutationError }] =
    useMutation(GOOGLE_LOGIN, {
      onCompleted(data) {
        localStorage.setItem("token", data.googleLogin.token);
        isLoggedInVar(true);
      },
      onError(error) {
        console.log(error);
      },
    });

  const responseGoogle = (response) => {
    let token = response.accessToken;
    if (token) {
      googleLogin({ variables: { input: { accessToken: token } } });
    }
  };

  let errors;
  if (mutationError) {
    errors = mutationError?.graphQLErrors?.map((e) => e.message);
  }

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageContent verticalPosition="center" horizontalPosition="center">
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h2>Suggestic</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiForm
              isInvalid={mutationError ? true : false}
              error={errors}
              component="form"
            >
              <GoogleLogin
                clientId="49413222691-44cnk7397u8k355o3lh0o7inn97qieqt.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                disabled={mutationLoading}
              />
            </EuiForm>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}
