import { gql } from "@apollo/client";

const BRANDED_FOOD_TAGS_UPDATE = gql`
mutation brandedFoodTagsUpdate($input: BrandedFoodTagsUpdateInput!) {
  brandedFoodTagsUpdate(input: $input) {
    brandedFood {
      id
      tags
    }
  }
}
`;

export default BRANDED_FOOD_TAGS_UPDATE