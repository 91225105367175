import React, { useState } from "react";
import {
  EuiButton,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiFormRow,
  EuiFieldText,
  EuiSelect,
  EuiComboBox,
  EuiForm,
} from "@elastic/eui";

const UnitSelect = () => {
  const options = [
    { value: "option_one", text: "Option one" },
    { value: "option_two", text: "Option two" },
    { value: "option_three", text: "Option three" },
  ];

  const [value, setValue] = useState(options[1].value);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <EuiSelect
      id="selectDocExample"
      options={options}
      value={value}
      onChange={(e) => onChange(e)}
      aria-label="Use aria labels when no actual label is in use"
    />
  );
};

const IngredientSelect = () => {
  const options = [
    {
      label: "Titan",
      "data-test-subj": "titanOption",
    },
    {
      label: "Enceladus",
    },
    {
      label: "Mimas",
    },
  ];

  const [selectedOptions, setSelected] = useState([options[1]]);

  const onChange = (selectedOptions) => {
    // We should only get back either 0 or 1 options.
    setSelected(selectedOptions);
  };

  return (
    <EuiComboBox
      placeholder="Select a single option"
      singleSelection={{ asPlainText: true }}
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
    />
  );
};

const form = (
  <EuiForm id="modalFormId" component="form">
    <EuiFormRow label="Quantity">
      <EuiFieldText name="quantity"/>
    </EuiFormRow>

    <EuiFormRow label="Unit">
      <UnitSelect />
    </EuiFormRow>

    <EuiFormRow label="Ingredient">
      <IngredientSelect />
    </EuiFormRow>
  </EuiForm>
);

export default function IngredientLineModal({ closeModal }) {
  return (
    <EuiModal onClose={closeModal} initialFocus="[name=quantity]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Ingredient Line</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>{form}</EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={closeModal} fill size="s">
          Add
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}
