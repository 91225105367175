import React, { useState } from "react";

import { EuiForm, EuiButton, EuiFormRow, EuiSpacer, EuiFieldText } from "@elastic/eui";

import { navigate } from "@reach/router";

import { useMutation } from "@apollo/client";

import COMMON_FOOD_NAME_UPDATE from "../../mutations/commonFoodNameUpdate";

export default function CommonFoodNameForm({ data }) {
  const [name, setName] = useState(data.name);

  const [update, { loading: mutationLoading, error: mutationError }] =
    useMutation(COMMON_FOOD_NAME_UPDATE, {
      onCompleted(data) {
        console.log(data);
        navigate(`/common-foods`);
      },
      onError(error) {
        console.log(error);
      },
    });

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  const _update = () => {
    let input = { id: data.id };
    input.name= name;
    update({ variables: { input: input } });
  };

  return (
    <>
      <EuiForm component="form">
        <EuiFormRow label="name">
          <EuiFieldText
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton fill onClick={_update} isLoading={mutationLoading}>
          Save
        </EuiButton>
      </EuiForm>
    </>
  );
}
