import { gql } from "@apollo/client";

const COMMON_FOOD_TAGS_UPDATE = gql`
mutation commonFoodTagsUpdate($input: CommonFoodTagsUpdateInput!) {
  commonFoodTagsUpdate(input: $input) {
    commonFood {
      id
      tags
    }
  }
}
`;

export default COMMON_FOOD_TAGS_UPDATE