import React, { useState } from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const NUTRIENTS = gql`
  {
    nutrients(first: 300) {
      edges {
        node {
          id
          name
          unitName
        }
      }
    }
  }
`;

export default function NutrientsComboBox({ setItem, item}) {
  const { loading, error, data } = useQuery(NUTRIENTS);
  const [selectedOptions, setSelected] = useState(item?[item] : []);

  if (error) return `Error! ${error}`;

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    if (Array.isArray(selectedOptions) && selectedOptions.length) {
      setItem(selectedOptions[0]);
    }
  };

  const options = [];
  if (data) {
    data.nutrients.edges.forEach((a) =>
      options.push({
        value: a.node.id,
        label: `${a.node.name} (unit ${a.node.unitName})`,
      })
    );
  }

  return (
    <EuiComboBox
      placeholder="Select category"
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      singleSelection={{ asPlainText: true }}
      isLoading={loading}
    />
  );
}
