import { gql } from "@apollo/client";

const COMMON_FOOD = gql`
  query commonFood($id: ID!) {
    commonFood(id: $id) {
      id
      foodDataId
      name
      description
      dataType
      commonNames
      tags
      ndbNumber
      uuid
      isActive
      portions {
        modifier
        seqNum
        amount
        gramWeight
        unit
      }
      nutrients {
        name
        amount
        unit
      }
      activities {
        user
        action
        date
        change
        fromTo
      }
    }
  }
`;

export default COMMON_FOOD;
