import React, { useState } from "react";

import {
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiSpacer,
} from "@elastic/eui";

import { navigate } from "@reach/router";

import { useMutation } from "@apollo/client";

import BRANDED_FOOD_TAGS_UPDATE from "../../mutations/brandedFoodTagsUpdate";
import BrandedFoodTagsComboBox from "../BrandedFoodTagsComboBox";

export default function BrandedFoodForm({ data }) {
  const [tags, setTags] = useState(
    data.tags ? data.tags.map((tag) => ({ label: tag })) : []
  );

  const [update, { loading: mutationLoading, error: mutationError }] =
    useMutation(BRANDED_FOOD_TAGS_UPDATE, {
      onCompleted(data) {
        console.log(data);
        navigate(`/branded-foods`);
      },
      onError(error) {
        console.log(error);
      },
    });

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  const _update = () => {
    let input = { id: data.id };
    input.tags = tags.map((t) => t.label)
    console.log(input)
    update({ variables: { input: input } });
  };

  return (
    <>
      <EuiForm component="form">
        <EuiFormRow label="Tags">
          <BrandedFoodTagsComboBox setTags={setTags} currentTags={tags} />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton fill onClick={_update} isLoading={mutationLoading}>
          Save
        </EuiButton>
      </EuiForm>

    </>
  );
}
