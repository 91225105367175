import React, { useState } from "react";
import {
  EuiPageContentHeader,
  EuiPageHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiFieldSearch,
  EuiSpacer,
  EuiButtonIcon,
  EuiPopover,
  EuiPopoverTitle,
} from "@elastic/eui";

import CommonFoodTable from "../../components/foods/CommonFoodTable";
import CommonFoodTagsComboBox from "../../components/CommonFoodTagsComboBox";

export default function CommonFoods() {
  const [query, setQuery] = useState("");
  const [isFilterPopover, setIsFilterPopover] = useState(false);
  // TODO: get tags from localstorage?
  const [tags, setTags] = useState([]);

  let table;
  let filters;

  if (Array.isArray(tags) && tags.length) {
    filters = [
      {
        terms: {
          tags: tags.map((t) => t.label),
        },
      },
    ];
  }

  if (query.length >= 3 || query.length === 0) {
    table = (
      <CommonFoodTable query={query !== "" ? query : null} filters={filters} />
    );
  }

  let filterPopover = (
    <EuiPopover
      button={
        <EuiButtonIcon
          aria-label="filter"
          iconType="filter"
          onClick={() => setIsFilterPopover((isFilterPopover) => !isFilterPopover)}
        />
      }
      isOpen={isFilterPopover}
      closePopover={() => setIsFilterPopover(false)}
      anchorPosition="downCenter"
    >
      <EuiPopoverTitle>Search Filters</EuiPopoverTitle>
      <div style={{ width: "300px" }}>
        <CommonFoodTagsComboBox setTags={setTags} currentTags={tags} compressed={true}/>
        <EuiSpacer size="l" />
      </div>
    </EuiPopover>
  );

  return (
    <>
      <EuiPageContentHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>Common Foods</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageContentHeader>

      <EuiPageContentBody>
        <EuiFieldSearch
          placeholder="Search..."
          fullWidth
          value={query}
          incremental={false}
          onChange={(e) => setQuery(e.target.value)}
          append={filterPopover}
        />
        <EuiSpacer size="xs" />

        {table}
      </EuiPageContentBody>
    </>
  );
}
