import React from "react";
import {
  EuiPageContentHeader,
  EuiPageHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiProgress,
  EuiBreadcrumbs,
  EuiFlexGroup,
  EuiFlexItem,
  EuiDescriptionList,
  EuiPanel,
  EuiHealth,
} from "@elastic/eui";

import { navigate } from "@reach/router";

import { useQuery } from "@apollo/client";
import moment from "moment";

import BRANDED_FOOD from "../../querys/brandedFood";

import TagBadges from "../../components/TagBadges";
import ActivityList from "../../components/AvtivityList";

export default function BrandedFood({ id }) {
  const { loading, error, data } = useQuery(BRANDED_FOOD, {
    variables: { id: id },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  let food = data.brandedFood;

  const breadcrumbs = [
    {
      text: "Branded Foods",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/branded-foods`);
      },
    },
    {
      text: "Detail",
    },
  ];

  const listItems = [
    {
      title: "Description",
      description: food.description,
    },
    {
      title: "GTIN UPC",
      description: food.gtinUpc,
    },
    {
      title: "Data Type",
      description: food.dataType,
    },
    {
      title: "Brand Owner",
      description: food.brandOwner,
    },
    {
      title: "Household Serving",
      description: food.householdServingFulltext,
    },
    {
      title: "Serving Size",
      description: food.servingSize,
    },
    {
      title: "Serving Size Unit",
      description: food.servingSizeUnit,
    },
    {
      title: "Data Source",
      description: food.dataSource,
    },
    {
      title: "Is Active",
      description: <EuiHealth color={food.isActive ? "success" : "danger"} />,
    },
    {
      title: "Category",
      description: food.brandedFoodCategory,
    },
    {
      title: "Data Type",
      description: food.dataType,
    },
    {
      title: "Tags",
      description: <TagBadges tags={food.tags} />,
    },
    {
      title: "Created At",
      description: moment(food.createdAt).fromNow(),
    },
    {
      title: "Updated At",
      description: moment(food.updatedAt).fromNow(),
    },
    {
      title: "Created By",
      description: food.createdBy,
    },
    {
      title: "uuid",
      description: food.uuid,
    },
  ];
  let nutrients = food.nutrients ? food.nutrients : [];

  let nutrientsListItems = nutrients.map((n) => {
    return { title: n.name, description: `${n.amount} ${n.unit}` };
  });
  nutrientsListItems.sort((a, b) => (a.title > b.title ? 1 : -1));

  return (
    <>
      <EuiPageContentHeader>
        <EuiPageHeaderSection>
          <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
          <EuiTitle>
            <h1>{food.name}</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageContentHeader>

      <EuiPageContentBody>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel hasShadow={false} hasBorder={true}>
              <EuiDescriptionList type="column" listItems={listItems} />{" "}
            </EuiPanel>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiPanel hasShadow={false} hasBorder={true}>
              <EuiDescriptionList
                type="column"
                listItems={nutrientsListItems}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="xs">
              <h1>Activity</h1>
            </EuiTitle>
            <EuiPanel hasShadow={false} hasBorder={false}>
              <ActivityList activities={food.activities} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageContentBody>
    </>
  );
}
