import { gql } from "@apollo/client";

const BRANDED_FOOD_NAME_UPDATE = gql`
mutation brandedFoodNameUpdate($input: BrandedFoodNameUpdateInput!) {
  brandedFoodNameUpdate(input: $input) {
    brandedFood {
      id
      name
    }
  }
}
`;

export default BRANDED_FOOD_NAME_UPDATE