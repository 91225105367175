import React, { useState } from "react";

import {
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiSpacer,
  EuiToast,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
} from "@elastic/eui";

import { useMutation } from "@apollo/client";
import { navigate } from "@reach/router";

import BRANDED_FOOD_CREATE from "../../mutations/brandedFoodCreate";
import BrandedFoodTagsComboBox from "../BrandedFoodTagsComboBox";
import BrandedFoodCategoryComboBox from "../BrandedFoodCategoryComboBox";
import NutrientInputList from "../NutrientInputList";

const INITIAL_CATEGORY = [
  {
    value: "QnJhbmRlZEZvb2RDYXRlZ29yeU5vZGU6MTI=",
    label: "",
  },
];

const INITIAL_NUTRIENTS = [
  {
    nutrient: {
      value: "TnV0cmllbnROb2RlOjEwMDg=",
      label: "Energy (unit KCAL)",
    },
    amount: "100",
  },
];

const UNITS = [
  { value: "g", text: "g" },
  { value: "ml", text: "ml" },
];

export default function BrandedFoodForm() {
  const [displayToast, setDisplayToast] = useState(false);

  const [name, setName] = useState("");
  const [servingSize, setServingSize] = useState(100);
  const [barcode, setBarcode] = useState("");
  const [brandOwner, setBrandOwner] = useState("");
  const [householdServingFulltext, setHouseholdServingFulltext] = useState("");
  const [unit, setUnit] = useState("g");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState(INITIAL_CATEGORY);
  const [nutrients, setNutrients] = useState(INITIAL_NUTRIENTS);


  const [create, { loading: mutationLoading, error: mutationError }] =
    useMutation(BRANDED_FOOD_CREATE, {
      onCompleted(data) {
        setDisplayToast(true);
        console.log(data);
        navigate(`/branded-foods`);
      },
      onError(error) {
        console.log(error);
      },
    });

  let errors;
  if (mutationError) {
    errors = mutationError?.graphQLErrors?.map((e) => e.message);
    if (mutationError?.message) {
      errors.push(mutationError.message);
    }
  }


  const _create = () => {
    let input = {
      name: name,
      servingSize: servingSize,
      servingSizeUnit: unit,
      barcode: barcode,
      brandOwner: brandOwner,
      householdServingFulltext: householdServingFulltext,
      category: category[0].value,
      tags: Array.isArray(tags) && tags.length ? tags.map((t) => t.label) : [],
      nutrients: nutrients.map((n) => {
        return { id: n.nutrient.value, amount: n.amount };
      }),
    };

    if (
      name !== "" &&
      servingSize !== "" &&
      barcode !== "" &&
      brandOwner !== "" &&
      householdServingFulltext !== ""
    ) {
      create({ variables: { input: input } });
    } else {
      console.log("Invalid form");
    }
  };

  const Toast = () => {
    return displayToast ? (
      <EuiToast
        title="Updated"
        color="success"
        iconType="check"
        onClose={() => setDisplayToast(false)}
      ></EuiToast>
    ) : null;
  };

  return (
    <>
      <EuiForm
        isInvalid={mutationError ? true : false}
        error={errors}
        component="form"
      >
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              label="Name"
              helpText="Product name"
            >
              <EuiFieldText
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </EuiFormRow>
            <EuiFormRow label="Barcode" helpText="UPC or GTIN">
              <EuiFieldText
                name="barcode"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
            </EuiFormRow>
            <EuiFormRow label="Brand Owner" helpText="e.g.: ACME INC.">
              <EuiFieldText
                name="brandOwner"
                value={brandOwner}
                onChange={(e) => setBrandOwner(e.target.value)}
              />
            </EuiFormRow>

            <EuiFormRow label="Household Serving" helpText="e.g.: 1 cookie">
              <EuiFieldText
                name="householdServingFulltext"
                value={householdServingFulltext}
                onChange={(e) => setHouseholdServingFulltext(e.target.value)}
              />
            </EuiFormRow>

            <EuiSpacer />
            <div>
              <EuiFlexGroup style={{ maxWidth: 420 }}>
                <EuiFlexItem>
                  <EuiFormRow label="Serving Size" helpText="">
                    <EuiFieldNumber
                      name="servingSize"
                      value={servingSize}
                      onChange={(e) => setServingSize(e.target.value)}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="Unit" helpText="">
                    <EuiSelect
                      id="selectDocExample"
                      options={UNITS}
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                      aria-label="unit-aria-label"
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
            <EuiSpacer />

            <EuiFormRow label="Category">
              <BrandedFoodCategoryComboBox
                setItem={setCategory}
                item={category}
              />
            </EuiFormRow>

            <EuiFormRow label="Tags">
              <BrandedFoodTagsComboBox setTags={setTags} currentTags={tags} />
            </EuiFormRow>
            <EuiSpacer />
          </EuiFlexItem>

          <EuiFlexItem>
            <NutrientInputList
              list={nutrients}
              setList={setNutrients}
              addText="Add"
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiButton fill onClick={_create} isLoading={mutationLoading}>
          Save
        </EuiButton>
      </EuiForm>

      <Toast />
    </>
  );
}
