import React, { useState } from "react";
import { EuiProgress, EuiBasicTable } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import BRANDED_FOODS from "../../querys/brandedFoods";
import { brandedFoodCalories } from "../../utils";
import TagBadges from "../TagBadges";

import { navigate } from "@reach/router";

export default function BrandedFoodTable({ query, filters }) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [cursor, setCursor] = useState(null);
  const { loading, error, data } = useQuery(BRANDED_FOODS, {
    variables: {
      query: query,
      first: pageSize,
      after: cursor,
      filters: filters,
    },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const onTableChange = ({ page = {} }) => {
    const { index: pageIndex, size: pageSize } = page;
    setPageIndex(pageIndex);
    setPageSize(pageSize);

    const cursorIndex = pageIndex * pageSize - 1;
    if (cursorIndex > 1) {
      setCursor(btoa(`arrayconnection:${cursorIndex}`));
    } else {
      setCursor(btoa(`arrayconnection:0`));
    }
  };

  const actions = [
    {
      name: "View",
      description: "View complete info",
      icon: "eye",
      type: "icon",
      onClick: (o) => {
        navigate(`/branded-foods/${o.node.id}`);
      },
    },
    {
      name: "Update Name",
      description: "Update branded food Name",
      icon: "indexEdit",
      type: "icon",
      onClick: (o) => {
        navigate(`/branded-foods/${o.node.id}/name-update`);
      },
    },
    {
      name: "Update Tags",
      description: "Update branded food tags",
      icon: "tag",
      type: "icon",
      onClick: (o) => {
        navigate(`/branded-foods/${o.node.id}/tags-update`);
      },
    },
    {
      name: "Update barcode",
      description: "Update branded food barcode",
      icon: "package",
      type: "icon",
      onClick: (o) => {
        navigate(`/branded-foods/${o.node.id}/barcode-update`);
      },
    },
  ];

  const columns = [
    {
      id: "name",
      name: "Name",
      field: "node",
      width: "350px",
      render: (node) => {
        return node.name;
      },
    },
    {
      id: "gtinUpc",
      name: "GTIN UPC",
      field: "node.gtinUpc",
    },
    {
      id: "brandOwner",
      name: "Brand Owner",
      field: "node.brandOwner",
    },
    {
      id: "brandName",
      name: "Brand",
      field: "node.brandName",
    },
    {
      id: "brandedFoodCategory",
      name: "Branded Food Category",
      field: "node.brandedFoodCategory",
    },
    {
      id: "tags",
      name: "Tags",
      field: "node",
      render: (node) => {
        return <TagBadges tags={node.tags} />;
      },
    },
    {
      id: "calories",
      field: "node",
      name: "Calories",
      render: (node) => {
        return brandedFoodCalories(node);
      },
    },
    {
      id: "actions",
      actions,
    },
  ];

  const totalItemCount = data.brandedFoods.count;

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [20, 40, 60],
  };

  return (
    <EuiBasicTable
      compressed={true}
      items={data.brandedFoods.edges}
      rowHeader="firstName"
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
    />
  );
}
