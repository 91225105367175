import { htmlIdGenerator } from "@elastic/eui";
import React, { useState } from "react";

import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiButtonEmpty,
  EuiPopover,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";

import { isLoggedInVar } from "../cache";

export default function HeaderUserMenu({ viewer }) {
  const id = htmlIdGenerator()();
  const [isOpen, setIsOpen] = useState(false);

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls={id}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={viewer.email} size="m" imageUrl={viewer.avatarUrl} />
    </EuiHeaderSectionItemButton>
  );

  const logout = () => {
    localStorage.removeItem("token");
    isLoggedInVar(false);
  };

  return (
    <EuiPopover
      id={id}
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="euiHeaderProfile"
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar
              name={viewer.email}
              size="xl"
              imageUrl={viewer.avatarUrl}
            />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{viewer.email}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  {/* <EuiFlexItem grow={false}>
                    <EuiLink>Edit profile</EuiLink>
                  </EuiFlexItem> */}

                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty onClick={logout} iconType="exit" size="xs">
                      Log out
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
}
