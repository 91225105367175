export const calories = (nutrients) => {
  let amount;
  let energy = nutrients.filter((f) => f.name === "Energy")[0];

  if (!energy) return 0;

  if (energy.unit.toLowerCase() === "kj") {
    amount = (energy.amount / 4.1868).toFixed(0);
  } else {
    amount = energy.amount;
  }
  return amount;
};

export const commonFoodCalories = (node) => {
  if (!node) return "--";

  let cal = calories(node.nutrients);

  if (cal) {
    return `${cal} kcal 100g`;
  }
  return "-";
};

export const brandedFoodCalories = (node) => {
  if (!node) return "--";

  let cal = calories(node.nutrients);

  if (cal) {
    let caloriesByServing = (cal * node.servingSize) / 100;
    return `${caloriesByServing.toFixed(0)} kcal ${node.servingSize}${
      node.servingSizeUnit
    }`;
  }
  return "-";
};

export const foodDataType = (node) => {
  if (!node) return "--";
  if (node.common) {
    return "Common";
  } else if (node.branded) {
    return "Branded";
  }
  return "Unknown";
};

// TODO: Use Environment variables instead of this
// https://create-react-app.dev/docs/adding-custom-environment-variables/
const utils = {
  API_URL:
    process.env.NODE_ENV === "production"
      ? "https://core-api.exegol.suggestic.com"
      : "http://localhost:8000",
};
export default utils;
