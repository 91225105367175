import React from "react";
import {
  EuiPageContentHeader,
  EuiPageHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiProgress,
  EuiBreadcrumbs,
} from "@elastic/eui";

import { navigate } from "@reach/router";

import { useQuery } from "@apollo/client";

import BRANDED_FOOD from "../../querys/brandedFood";
import BrandedFoodNameForm from "../../components/foods/BrandedFoodNameForm";

export default function BrandedFoodNameUpdate({ id }) {
  const { loading, error, data } = useQuery(BRANDED_FOOD, {
    variables: { id: id },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  let food = data.brandedFood;

  const breadcrumbs = [
    {
      text: "Branded Foods",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/branded-foods`);
      },
    },
    {
      text: `${food.name}`,
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/branded-foods/${food.id}`);
      },
    },
    {
      text: "Edit",
    },
  ];

  return (
    <>
      <EuiPageContentHeader>
        <EuiPageHeaderSection>
          <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
          <EuiTitle>
            <h1>{food.name}</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageContentHeader>

      <EuiPageContentBody>
        <BrandedFoodNameForm data={food} />
      </EuiPageContentBody>
    </>
  );
}
