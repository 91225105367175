import conf from "./utils";

import {
  HttpLink,
  ApolloLink,
  ApolloClient,
  concat,
} from "@apollo/client";

import { cache } from "./cache";

const httpLink = new HttpLink({
  uri: `${conf.API_URL}/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: token ? `Token ${token}` : "",
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink),
});

export default client;
