import { gql } from "@apollo/client";

const BRANDED_FOOD = gql`
  query brandedFood($id: ID!) {
    brandedFood(id: $id) {
      id
      name
      description
      gtinUpc
      foodDataId
      dataType
      commonNames
      tags
      ndbNumber
      uuid
      brandOwner
      servingSize
      servingSizeUnit
      dataSource
      isActive
      brandedFoodCategory
      householdServingFulltext
      createdBy
      dataType
      createdAt
      updatedAt
      nutrients {
        name
        amount
        unit
      }
      activities {
        user
        action
        date
        change
        fromTo
      }
    }
  }
`;

export default BRANDED_FOOD;
