import { gql } from "@apollo/client";

const BRANDED_FOOD_BARCODE_UPDATE = gql`
mutation brandedFoodBarcodeUpdate($input: BrandedFoodBarcodeUpdateInput!) {
  brandedFoodBarcodeUpdate(input: $input) {
    brandedFood {
      id
      tags
    }
  }
}
`;

export default BRANDED_FOOD_BARCODE_UPDATE