import { gql } from "@apollo/client";

const COMMON_FOODS = gql`
  query commonFoods($query: String, $first: Int, $after: String, $filters: [GenericScalar]) {
    commonFoods(query: $query, first: $first, after: $after, filters: $filters) {
      count
      edges {
        node {
          id
          name
          description
          promote
          commonNames
          tags
          nutrients {
            name
            unit
            amount
          }
        }
      }
    }
  }
`;

export default COMMON_FOODS;
