import React from "react";
import {
  EuiPageContentHeader,
  EuiPageHeaderSection,
  EuiPageContentBody,
  EuiBreadcrumbs,
} from "@elastic/eui";

import { navigate } from "@reach/router";

import BrandedFoodFormCreate from "../../components/foods/BrandedFoodFormCreate";

export default function BrandedFoodCreate() {

  const breadcrumbs = [
    {
      text: "Branded Foods",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/branded-foods`);
      },
    },
    {
      text: "Create",
    },
  ];

  return (
    <>
      <EuiPageContentHeader>
        <EuiPageHeaderSection>
          <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
        </EuiPageHeaderSection>
      </EuiPageContentHeader>

      <EuiPageContentBody>
        <BrandedFoodFormCreate />
      </EuiPageContentBody>
    </>
  );
}
