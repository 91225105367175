import {
  EuiIcon,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiShowFor,
  EuiPageBody,
  EuiPageContent,
  EuiPage,
  EuiProgress,
} from "@elastic/eui";
import React, { useState } from "react";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import { EuiCollapsibleNav, EuiCollapsibleNavGroup } from "@elastic/eui";
import { EuiHeaderSectionItemButton, EuiHeader } from "@elastic/eui";
import { EuiPinnableListGroup, EuiListGroupItem } from "@elastic/eui";
import { Router, navigate } from "@reach/router";
import { gql, useQuery } from "@apollo/client";

import Logo from "../components/Logo";

import Home from "./home";

import CommonFoods from "./foods/commonFoods";
import CommonFood from "./foods/commonFood";
import CommonFoodTagsUpdate from "./foods/commonFoodTagsUpdate";
import CommonFoodNameUpdate from "./foods/commonFoodNameUpdate";

import BrandedFoods from "./foods/brandedFoods";
import BrandedFood from "./foods/brandedFood";
import BrandedFoodTagsUpdate from "./foods/brandedFoodTagsUpdate";
import BrandedFoodBarcodeUpdate from "./foods/brandedFoodBarcodeUpdate";
import BrandedFoodNameUpdate from "./foods/brandedFoodNameUpdate";
import BrandedFoodCreate from "./foods/brandedFoodCreate";

// import Ingredients from "./ingredients/list";
import Recipes from "./recipes/list";
import NewRecipe from "./recipes/new";

import HeaderUserMenu from "../components/HeaderUserMenu";

const VIEWER = gql`
  {
    me {
      username
      firstName
      email
      avatarUrl
    }
  }
`;

const NotFound = () => <p>No page found</p>;

const TopLinks = [
  {
    label: "Home",
    iconType: "home",
    isActive: true,
    "aria-current": true,
    onClick: () => navigate(`/`),
    pinnable: false,
  },
];

// const RecipeLinks = [
//   {
//     label: "All",
//     onClick: () => {
//       navigate(`/recipes`);
//     },
//     iconType: "tableDensityNormal",
//   },
//   { label: "Most Recent", onClick: () => {}, iconType: "clock" },
//   { label: "Fovorites", onClick: () => {}, iconType: "heart" },
// ];

const AdminLinks = [
  // {
  //   label: "Ingredients",
  //   onClick: () => {
  //     navigate(`/ingredients`);
  //   },
  //   iconType: "database",
  // },
  {
    label: "Common Foods",
    onClick: () => {
      navigate(`/common-foods`);
    },
    iconType: "database",
  },
  {
    label: "Branded Foods",
    onClick: () => {
      navigate(`/branded-foods`);
    },
    iconType: "database",
  },
];

export default function Index() {
  const { loading, error, data } = useQuery(VIEWER, {
    variables: {},
    fetchPolicy: "network-only",
  });
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [navIsDocked, setNavIsDocked] = useState(
    JSON.parse(String(localStorage.getItem("nav2IsDocked"))) || true
  );

  /**
   * Accordion toggling
   */
  const [openGroups, setOpenGroups] = useState(
    JSON.parse(String(localStorage.getItem("openNavGroups"))) || [
      "Recipes",
      "Admin",
    ]
  );

  // Save which groups are open and which are not with state and local store
  const toggleAccordion = (isOpen, title) => {
    if (!title) return;
    const itExists = openGroups.includes(title);
    if (isOpen) {
      if (itExists) return;
      openGroups.push(title);
    } else {
      const index = openGroups.indexOf(title);
      if (index > -1) {
        openGroups.splice(index, 1);
      }
    }
    setOpenGroups([...openGroups]);
    localStorage.setItem("openNavGroups", JSON.stringify(openGroups));
  };

  /**
   * Pinning
   */
  const [pinnedItems, setPinnedItems] = useState(
    JSON.parse(String(localStorage.getItem("pinnedItems"))) || []
  );

  const addPin = (item) => {
    if (!item || find(pinnedItems, { label: item.label })) {
      return;
    }
    item.pinned = true;
    const newPinnedItems = pinnedItems ? pinnedItems.concat(item) : [item];
    setPinnedItems(newPinnedItems);
    localStorage.setItem("pinnedItems", JSON.stringify(newPinnedItems));
  };

  const removePin = (item) => {
    const pinIndex = findIndex(pinnedItems, { label: item.label });
    if (pinIndex > -1) {
      item.pinned = false;
      const newPinnedItems = pinnedItems;
      newPinnedItems.splice(pinIndex, 1);
      setPinnedItems([...newPinnedItems]);
      localStorage.setItem("pinnedItems", JSON.stringify(newPinnedItems));
    }
  };

  function alterLinksWithCurrentState(links, showPinned) {
    return links.map((link) => {
      const { pinned, ...rest } = link;
      return {
        pinned: showPinned ? pinned : false,
        ...rest,
      };
    });
  }

  function addLinkNameToPinTitle(listItem) {
    return `Pin ${listItem.label} to top`;
  }

  function addLinkNameToUnpinTitle(listItem) {
    return `Unpin ${listItem.label}`;
  }

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );
  if (error) return `Error! ${error}`;

  const collapsibleNav = (
    <EuiCollapsibleNav
      id="guideCollapsibleNavAllExampleNav"
      aria-label="Main navigation"
      isOpen={navIsOpen}
      isDocked={navIsDocked}
      button={
        <EuiHeaderSectionItemButton
          aria-label="Toggle main navigation"
          onClick={() => setNavIsOpen(!navIsOpen)}
        >
          <EuiIcon type={"menu"} size="m" aria-hidden="true" />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen(false)}
    >
      {/* Shaded pinned section always with a home item */}
      <EuiFlexItem grow={false} style={{ flexShrink: 0 }}>
        <EuiCollapsibleNavGroup
          background="light"
          className="eui-yScroll"
          style={{ maxHeight: "40vh" }}
        >
          <EuiPinnableListGroup
            aria-label="Pinned links" // A11y : Since this group doesn't have a visible `title` it should be provided an accessible description
            listItems={alterLinksWithCurrentState(TopLinks).concat(
              alterLinksWithCurrentState(pinnedItems, true)
            )}
            unpinTitle={addLinkNameToUnpinTitle}
            onPinClick={removePin}
            maxWidth="none"
            color="text"
            gutterSize="none"
            size="s"
          />
        </EuiCollapsibleNavGroup>
      </EuiFlexItem>

      <EuiHorizontalRule margin="none" />

      <EuiFlexItem className="eui-yScroll">
        {/* <EuiCollapsibleNavGroup
          title="Recipes"
          iconType="analyzeEvent"
          isCollapsible={true}
          initialIsOpen={openGroups.includes("Recipes")}
          onToggle={(isOpen) => toggleAccordion(isOpen, "Recipes")}
        >
          <EuiPinnableListGroup
            aria-label="Recipe" // A11y : EuiCollapsibleNavGroup can't correctly pass the `title` as the `aria-label` to the right HTML element, so it must be added manually
            listItems={alterLinksWithCurrentState(RecipeLinks)}
            pinTitle={addLinkNameToPinTitle}
            onPinClick={addPin}
            maxWidth="none"
            color="subdued"
            gutterSize="none"
            size="s"
          />
        </EuiCollapsibleNavGroup> */}

        <EuiCollapsibleNavGroup
          title="Admin"
          iconType="wrench"
          isCollapsible={true}
          initialIsOpen={openGroups.includes("Admin")}
          onToggle={(isOpen) => toggleAccordion(isOpen, "Admin")}
        >
          <EuiPinnableListGroup
            aria-label="Admin"
            listItems={alterLinksWithCurrentState(AdminLinks)}
            pinTitle={addLinkNameToPinTitle}
            onPinClick={addPin}
            maxWidth="none"
            color="subdued"
            gutterSize="none"
            size="s"
          />
        </EuiCollapsibleNavGroup>

        {/* Docking button only for larger screens that can support it*/}
        <EuiShowFor sizes={["l", "xl"]}>
          <EuiCollapsibleNavGroup>
            <EuiListGroupItem
              size="xs"
              color="subdued"
              label={`${navIsDocked ? "Undock" : "Dock"} navigation`}
              onClick={() => {
                setNavIsDocked(!navIsDocked);
                localStorage.setItem(
                  "nav2IsDocked",
                  JSON.stringify(!navIsDocked)
                );
              }}
              iconType={navIsDocked ? "lock" : "lockOpen"}
            />
          </EuiCollapsibleNavGroup>
        </EuiShowFor>
      </EuiFlexItem>
    </EuiCollapsibleNav>
  );

  const leftSectionItems = [collapsibleNav, <Logo />];

  return (
    <>
      <EuiHeader
        position="fixed"
        sections={[
          {
            items: leftSectionItems,
            borders: "none",
          },
          {
            items: [<HeaderUserMenu viewer={data.me} />],
          },
        ]}
      />

      <EuiPage paddingSize="none">
        <EuiPageBody>
          <EuiPageContent hasShadow={false}>
            <Router>
              <NotFound default />
              <Home path="/" viewer={data.me} />

              <CommonFoods path="/common-foods" />
              <CommonFood path="/common-foods/:id" />
              <CommonFoodTagsUpdate path="/common-foods/:id/tags-update" />
              <CommonFoodNameUpdate path="/common-foods/:id/name-update"/>

              <BrandedFoods path="/branded-foods"/>
              <BrandedFood path="/branded-foods/:id" />
              <BrandedFoodTagsUpdate path="/branded-foods/:id/tags-update" />
              <BrandedFoodBarcodeUpdate path="/branded-foods/:id/barcode-update" />
              <BrandedFoodNameUpdate path="/branded-foods/:id/name-update"/>
              <BrandedFoodCreate path="/branded-foods/create" />

              {/* <Ingredients path="/ingredients" /> */}
              {/* <Recipes path="/recipes" />
              <NewRecipe path="/recipes/new" /> */}
            </Router>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  );
}
