import logo from "../suggestic-logo-small.png";

const Logo = () => (
  <>
    <img
      className="euiHeaderLogo__icon"
      src={logo}
      alt="Suggestic"
      width={220 / 10}
      height={190 / 10}
    />
    <span className="euiHeaderLogo__text">Suggestic</span>
  </>
);

export default Logo;
