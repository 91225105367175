import { gql } from "@apollo/client";

const GOOGLE_LOGIN = gql`
  mutation googleLogin($input: GoogleLoginInput!) {
    googleLogin(input: $input) {
      success
      message
      token
      me {
        email
        avatarUrl
      }
    }
  }
`;

export default GOOGLE_LOGIN;
