import React, { useState } from "react";
import { EuiProgress, EuiBasicTable } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import COMMON_FOODS from "../../querys/commonFoods";
import { commonFoodCalories } from "../../utils";
import TagBadges from "../TagBadges";
import { navigate } from "@reach/router";

export default function CommonFoodTable({ query, filters }) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [cursor, setCursor] = useState(null);
  const { loading, error, data } = useQuery(COMMON_FOODS, {
    variables: {
      query: query,
      first: pageSize,
      after: cursor,
      filters: filters,
    },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const onTableChange = ({ page = {} }) => {
    const { index: pageIndex, size: pageSize } = page;
    setPageIndex(pageIndex);
    setPageSize(pageSize);

    const cursorIndex = pageIndex * pageSize - 1;
    if (cursorIndex > 1) {
      setCursor(btoa(`arrayconnection:${cursorIndex}`));
    } else {
      setCursor(btoa(`arrayconnection:0`));
    }
  };

  const actions = [
    {
      name: 'View',
      description: 'View complete info',
      icon: 'eye',
      type: 'icon',
      onClick: (o)=>{navigate(`/common-foods/${o.node.id}`)},
    },
    {
      name: 'Update Name',
      description: 'Update common food name',
      icon: 'indexEdit',
      type: 'icon',
      onClick: (o)=>{navigate(`/common-foods/${o.node.id}/name-update`)},
    },
    {
      name: 'Update Tags',
      description: 'Update common food tags',
      icon: 'tag',
      type: 'icon',
      onClick: (o)=>{navigate(`/common-foods/${o.node.id}/tags-update`)},
    },
  ];

  const columns = [
    {
      id: "name",
      name: "Name",
      field: "node",
      width: "350px",
      render: (node) => {
        return node.name;
      },
    },
    {
      id: "description",
      name: "Description",
      field: "node.description",
    },
    {
      id: "calories",
      field: "node",
      name: "Calories",
      render: (node) => {
        return commonFoodCalories(node);
      },
    },
    {
      id: "tags",
      name: "Tags",
      field: "node",
      render: (node) => {
        return <TagBadges tags={node.tags}/>
      },
    },
    {
      id: 'actions',
      actions
    },
  ];

  const totalItemCount = data.commonFoods.count;

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [20, 40, 60],
  };

  return (
    <EuiBasicTable
      compressed={true}
      items={data.commonFoods.edges}
      rowHeader="firstName"
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
    />
  );
}
