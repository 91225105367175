import React from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const BRANDED_FOOD_TAGS = gql`
  {
    brandedFoodAggs(field: "tags") {
      key
      docCount
    }
  }
`;

export default function TagsComboBox({ currentTags, setTags, compressed=false }) {
  const { loading, error, data } = useQuery(BRANDED_FOOD_TAGS);

  if (error) return `Error! ${error}`;

  const options = [];
  if (data) {
    data.brandedFoodAggs.forEach((tag) => options.push({ label: tag.key }));
  }

  const onCreateOption = (searchValue = []) => {
    const normalizedSearchValue = searchValue.trim();

    if (!normalizedSearchValue) {
      return;
    }

    const customOption = { label: normalizedSearchValue };
    options.push(customOption);
    currentTags.push(customOption);
  };


  return (
    <EuiComboBox
      placeholder="Tags"
      options={options}
      selectedOptions={currentTags}
      onChange={(tags) => setTags(tags)}
      isClearable={true}
      data-test-subj="recipeTags"
      isLoading={loading}
      fullWidth={false}
      compressed={compressed}
      onCreateOption={onCreateOption}
    />
  );
}
