import React, { useState } from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const BRANDED_FOOD_CATEGORIES = gql`
  {
    brandedFoodCatogories {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`;

export default function BrandefFoodCategoryComboBox({ setItem, item }) {
  const { loading, error, data } = useQuery(BRANDED_FOOD_CATEGORIES);
  const [selectedOptions, setSelected] = useState(item);

  if (error) return `Error! ${error}`;

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    if (Array.isArray(selectedOptions) && selectedOptions.length) {
      setItem([selectedOptions[0]]);
    }
  };

  const options = [];
  if (data) {
    data.brandedFoodCatogories.edges.forEach((a) =>
      options.push({ value: a.node.id, label: a.node.name })
    );
  }

  return (
    <EuiComboBox
      placeholder="Select category"
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      singleSelection={{ asPlainText: true }}
      isLoading={loading}
    />
  );
}
