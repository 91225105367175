import React from "react";
import { EuiComment } from "@elastic/eui";
import moment from "moment";

export default function ActivityList({ activities }) {
  const comments = activities.map((item, i) => {
    let fromTo;
    if (item.fromTo) {
      fromTo = `${item.fromTo.field} from: ${item.fromTo.from} to: ${item.fromTo.to}`
    }

    var utcDate = moment.utc(item.date).toDate();
    var timestamp = moment(utcDate).local().fromNow();
  
    return (
      <EuiComment
        username={`User id: ${item.user}`}
        type="update"
        event={`${item.action}`}
        timestamp={timestamp}
        key={`comment-activity-${i}`}
      >{fromTo}
      </EuiComment>
    );
  });

  return <div>{comments}</div>;
}
