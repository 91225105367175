import { gql } from "@apollo/client";

const COMMON_FOOD_NAME_UPDATE = gql`
mutation commonFoodNameUpdate($input: CommonFoodNameUpdateInput!) {
  commonFoodNameUpdate(input: $input) {
    commonFood {
      id
      name
    }
  }
}
`;

export default COMMON_FOOD_NAME_UPDATE