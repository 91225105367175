import { gql } from "@apollo/client";

const BRANDED_FOODS = gql`
  query brandedFoods($query: String, $first: Int, $after: String, $filters: [GenericScalar]) {
    brandedFoods(query: $query, first: $first, after: $after, filters: $filters) {
      count
      edges {
        node {
          id
          name
          gtinUpc
          description
          dataType
          brandOwner
          brandName
          householdServingFulltext
          brandedFoodCategory
          promote
          servingSizeUnit
          servingSize
          tags
          nutrients {
            name
            unit
            amount
          }
        }
      }
    }
  }
`;

export default BRANDED_FOODS;
