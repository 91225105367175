import { gql } from "@apollo/client";

const BRANDED_FOOD_CREATE = gql`
mutation brandedFoodCreate($input: BrandedFoodCreateInput!) {
  brandedFoodCreate(input: $input) {
    success
    # brandedFood {
    #   name
    # }
  }
}
`;

export default BRANDED_FOOD_CREATE