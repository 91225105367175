
import React from "react";
import ReactDOM from "react-dom";

import './styles/globalCustom.css'

import "@elastic/eui/dist/eui_theme_amsterdam_dark.css";
// import "@elastic/eui/dist/eui_theme_amsterdam_light.css";
// import './styles/suggestic-light/eui_theme_suggestic_light.css'
// import './styles/euiCustom.css'


import Login from "./pages/login";
import Pages from "./pages";
import client from "./client";

import * as serviceWorker from "./serviceWorker";

import {
  ApolloProvider,
  gql,
  useQuery,
} from "@apollo/client";


const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

function IsLoggedIn() {
  const { data } = useQuery(IS_LOGGED_IN);
  return data.isLoggedIn ? <Pages /> : <Login />;
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <IsLoggedIn />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
